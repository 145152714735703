<template>
  
  <div>

    <chronometer ref="chronometer" :redirect="redirect" :time="time" v-show="false"/>

    <v-progress-circular
      color="warning"
      size="170"
      width="8"
      indeterminate
    >

      <v-avatar size="150">
        <v-img :src="$store.state.urlApi+'step/'+this.stepId+'/image?auth='+$store.state.accessToken" />
      </v-avatar>

    </v-progress-circular>

  </div>

</template>

<script>

import chronometer from './Chronometer.vue'

export default {
  name: 'circular-chronometer',
  props: ['time','redirect','stepId'],
  components: {
    chronometer
  },
  methods: {
    initChronometer: function(){
      this.$refs.chronometer.initChronometer()
    }
  }
}
</script>

<style>

</style>
