<template>

<v-content>
 <v-container fluid>
  <v-row align="center" justify="center">
    <v-col xs="12" sm="6" md="4">
      <v-row
        align="center"
        justify="center"
      >
        <chronometer ref="chronometer" :redirect="$store.state.step.redirect" :time="0" :stepId="$store.state.step.id"/>
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="mt-2"
      >
        <v-card
          width="100%"
        >
          <p class="text-center pa-2 font-weight-bold title">{{$store.state.step.message}}</p>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
  </v-container>
</v-content>

</template>

<script>

import chronometer from '../components/chronometer/CircularChronometer.vue'

export default {
  name: 'instructions',
  components: {
    chronometer
  },
  mounted(){
    this.$refs.chronometer.initChronometer()
  },
}
</script>

<style>

</style>
